import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { AuthService } from '../../../../components/auth/auth.service';
import { ImpersonationService } from '../../../../components/auth/impersonation.service';

import { DarkPoolService } from '../dark-pool.service';

import { DarkPoolDomainsToIgnoreComponent } from '../dark-pool-domains-to-ignore/dark-pool-domains-to-ignore.component';
//import { SegmentSelectorComponent } from '../../../shared/segment-selector/segment-selector.component.js';

import moment from 'moment-timezone';

@Component({
  selector: 'dark-pool-time-period',
  template: require('./dark-pool-time-period.html')
})
export class DarkPoolTimePeriodComponent implements OnInit {
  @Input() settings;
  @Input() showEdit = true;
  @Input() refresh: (args: any) => void;

  static parameters = [BsModalService, AuthService, ImpersonationService, DarkPoolService];
  constructor(modalService: BsModalService, authService: AuthService, impersonation: ImpersonationService, darkPoolService: DarkPoolService) {
    this.modalService = modalService;
    this.authService = authService;
    this.impersonation = impersonation;
    this.darkPoolService = darkPoolService;
    this.editing = false;
    this.updating = false;
    this.errorMsg = null;

    //Find the threshold with problematic contact percentage closest to 20%
    this.idealValue = 0.2;

    this.domainsToIgnore = [];
    //this.segmentsToIgnore = [];
  }

  ngOnInit() {
    this.isDemo = this.impersonation.isDemoMode();
    this.authService.getCurrentUser().then(user => {
      this.currentUser = user;
    });

    this.authService.getCurrentOrg().then(org => {
      if(typeof org !== 'undefined') {
        this.currentOrg = org;
        this.domainsToIgnore = this.darkPoolService.getDomainsToIgnore(this.currentOrg);
      }
    });

    //Clear temp settings when impersonating.
    if(this.impersonation.isImpersonating()) {
      this.darkPoolService.tempSettings = null;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    for(const propName in changes) {
      if(propName === 'settings') {
        if(this.settings && typeof this.settings.startDate !== 'undefined') {
          //Use the settings.
          this.darkPoolService.getDarkPoolTimeRange(this.currentOrg._id).then(response => {
            //Min / max always come from the Org settings.
            this.minDate = new Date(response.start_date);
            this.maxDate = new Date(response.end_date);

            if(this.darkPoolService.tempSettings) {
              //We have a saved dark pool tempSettings
              this.displayStartDate = new Date(this.darkPoolService.tempSettings.startDate);
              this.displayEndDate = new Date(this.darkPoolService.tempSettings.endDate);
              this.displayThreshold = this.darkPoolService.tempSettings.threshold;
  
            } else {
              this.displayStartDate = new Date(response.start_date);
              this.displayEndDate = new Date(response.end_date); 
              this.displayThreshold = this.settings.threshold;
            }

            //For the datepickers
            this.newStartDate = new Date(this.displayStartDate);
            this.newEndDate = new Date(this.displayEndDate);

            //Set threshold value
            this.newThreshold = this.displayThreshold;
          });

          this.domainsToIgnore = this.darkPoolService.getDomainsToIgnore(this.currentOrg);
          //this.segmentsToIgnore = this.darkPoolService.getSegmentsToIgnore();
        }
      }
    }
  }

  clearTempSettings() {
    this.darkPoolService.tempSettings = null;

    //For the datepickers
    this.newStartDate = new Date(this.settings.startDate);
    this.newEndDate = new Date(this.settings.endDate);

    //Set threshold value
    this.newThreshold = this.settings.threshold;
  }

  edit() {
    this.editing = true;
    this.errorMsg = null;
  }

  openDomainsSelector() {
    const initialState = { domainsToIgnore: this.domainsToIgnore.slice() }; //make a copy, so we can cancel.
    this.modalRef = this.modalService.show(DarkPoolDomainsToIgnoreComponent, { initialState, class: 'modal-lg' });

    var sub = this.modalService.onHidden.subscribe(() => {
      if(this.modalRef.content.okClicked) {
        this.domainsToIgnore = this.modalRef.content.domainsToIgnore;
        this.domainsToIgnore.sort();
        this.darkPoolService.setDomainsToIgnore(this.currentOrg, this.domainsToIgnore);
        this.refresh();
      }
      if(sub) sub.unsubscribe();
    });
  }

  /*
  openSegmentSelector() {
    var initialState = {
      orgId: this.currentUser.organizationId,
      message: 'Select segments to exclude from Dark Pool reporting.',
      multiSelect: true,
      selectedSegments: this.segmentsToIgnore
    };

    this.modalRef = this.modalService.show(SegmentSelectorComponent, {
      initialState,
      class: 'modal-md'
    });

    var sub = this.modalService.onHidden.subscribe(() => {
      // Make sure that onHidden was called by modal
      if(this.modalRef.content.okClicked) {
        this.segmentsToIgnore = this.modalRef.content.selectedSegments;
        this.darkPoolService.setSegmentsToIgnore(this.segmentsToIgnore);
        this.refresh();
      }

      if(sub) sub.unsubscribe();
    });
  }
  */

  save() {
    this.errorMsg = null;

    //Check dates -- not overlapping, etc.
    var startDateChk = moment(this.newStartDate);
    var endDateChk = moment(this.newEndDate);

    if(startDateChk.isSameOrAfter(endDateChk)) {
      this.errorMsg = 'Start date must be before the end date.';
      return;
    }

    //If only the dates changed, and check the threshold and maybe set it.
    var displayStartDateChk = moment(this.displayStartDate);
    var displayEndDateChk = moment(this.displayEndDate);

    if((!displayStartDateChk.isSame(startDateChk, 'day')
       || !displayEndDateChk.isSame(endDateChk, 'day'))
       && this.displayThreshold === this.newThreshold) {
      //Check the thresholds
      this.updating = true;

      var startDate = this.newStartDate.toISOString();
      var endDate = this.newEndDate.toISOString();
      this.darkPoolService.checkThresholds(this.currentUser.organizationId, startDate, endDate)
        .then(data => {
          if(data.length > 0) {
            const thresholdData = data[0];
            const closest = thresholdData.values.reduce((a, b) => {
              let aDiff = Math.abs(a - this.idealValue);
              let bDiff = Math.abs(b - this.idealValue);
              if(aDiff == bDiff) {
                //Choose largest vs smallest (> vs <)
                return a > b ? a : b;
              }
              else {
                return bDiff < aDiff ? b : a;
              }
            });

            //Set the newThreshold.
            const idx = thresholdData.values.indexOf(closest);
            this.newThreshold = thresholdData.columns[idx];
            this.closeAndRefresh();
          }
          else {
            this.closeAndRefresh();
          }
        })
        .catch(e => {
          console.log('Exception checking Dark Pool threshold.', e);
        });
    }
    else {
      this.closeAndRefresh();
    }
  }

  closeAndRefresh() {
    //Save the chosen time period and threshold in the tempSettings, but
    // only if there are changes.
    var checkStartDate = new Date(this.settings.startDate);
    var checkEndDate = new Date(this.settings.endDate);

    checkStartDate.setHours(12);
    checkStartDate.setMinutes(0);
    checkStartDate.setSeconds(0);

    checkEndDate.setHours(12);
    checkEndDate.setMinutes(0);
    checkEndDate.setSeconds(0);

    if(this.newThreshold !== this.settings.threshold
       || checkStartDate.getTime() !== this.newStartDate.getTime()
       || checkEndDate.getTime() !== this.newEndDate.getTime()) {
      this.darkPoolService.tempSettings = {
        startDate: this.newStartDate,
        endDate: this.newEndDate,
        threshold: this.newThreshold
      };
    }

    //Update dipslay
    this.displayStartDate = new Date(this.newStartDate);
    this.displayEndDate = new Date(this.newEndDate);
    this.displayThreshold = this.newThreshold;

    this.updating = false;
    this.editing = false;
    this.refresh();
  }

  cancel() {
    this.editing = false;
    this.newStartDate = new Date(this.settings.startDate);
    this.newEndDate = new Date(this.settings.endDate);
  }
}
